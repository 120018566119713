<template>
  <div class="choose-risk-container">
    <div class="choose-risk__input_group is-hidden-mobile">
      <div class="choose-risk__input">
        <div class="choose-risk__input__title">
          Monto total a apostar
        </div>
        <div class="choose-risk__input__box">
          <span>$ </span>
          <input ref="input" v-model="betAmount" type="text" @blur="validateAmount" @click="selectAllAmount" />
          <span>mxn</span>
        </div>
      </div>
      <div class="choose-risk__input">
        <div class="choose-risk__input__title">
          usuario seleccionado
        </div>
        <div class="choose-risk__input__box_user">
          {{ selectedUser }}
        </div>
      </div>
    </div>
    <div class="choose-risk__title">
      Elige el riesgo de tu apuesta
    </div>
    <div class="choose-risk__body">
      <Checkbox
        v-for="(option, index) in displayRiskOptions"
        :key="index"
        v-model="currentRisk"
        class="risk-checkbox"
        :text-value="option.value"
        hasTextValue
        :label="option.label"
        selectColor="#c9e8af"
        :class="{ selected: currentRisk == option.value }"
      />
    </div>
    <button class="choose-risk__button" @click="goNext">
      Siguiente
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Checkbox from '@/components/Elements/Checkbox';

import { clickBtnEligeRiesgoSiguiente } from '@/utils/analytics';

export default {
  name: 'SetBetUser',
  components: { Checkbox },
  data: () => ({
    freeRiskOptions: [
      { label: 'Bajo riesgo - Bajo rendimiento', value: 'free low' },
      { label: 'Mediano riesgo - Mediano rendimiento', value: 'free medium' },
      { label: 'Alto riesgo - Alto rendimiento', value: 'free high' },
    ],
    premiumRiskOptions: [
      { label: 'Conservador', value: 'premium low' },
      { label: 'Recreacional', value: 'premium medium' },
      { label: 'Arriesgado', value: 'premium high' },
    ],
  }),
  mounted() {
    if (this.getPremiumAccount) {
      this.setRiskType('premium low');
      this.setSelectedRisk(4);
    } else {
      this.setRiskType('free low');
      this.setSelectedRisk(1);
    }
  },
  computed: {
    displayRiskOptions() {
      return this.getPremiumAccount ? this.premiumRiskOptions : this.freeRiskOptions;
    },
    ...mapGetters('tips', ['getBetAmount', 'getUserType', 'getRiskType']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    betAmount: {
      get: function() {
        return this.getBetAmount;
      },
      set: function(newVal) {
        const positiveRegex = new RegExp(/^[1-9]\d*$/);
        if (positiveRegex.test(newVal)) {
          this.setBetAmount(newVal);
        } else {
          this.$refs['input'].value = this.betAmount;
        }
      },
    },
    currentRisk: {
      get: function() {
        return this.getRiskType;
      },
      set: function(newVal) {
        this.setRiskType(newVal);
        const riskIndex = this[this.getPremiumAccount ? 'premiumRiskOptions' : 'freeRiskOptions'].findIndex(
          r => r.value === newVal,
        );
        const riskAsNumber = this.getPremiumAccount ? riskIndex + 4 : riskIndex + 1;
        this.setSelectedRisk(riskAsNumber);
      },
    },
    selectedUser() {
      return this.getPremiumAccount ? 'Usuario Premium' : 'Usuario Free';
    },
  },
  methods: {
    ...mapMutations('tips', ['setBetAmount', 'setRiskType', 'setSelectedRisk']),
    goNext() {
      clickBtnEligeRiesgoSiguiente(this.currentRisk, this.getPremiumAccount, this.getIsGolstats);

      if (Number(this.betAmount) >= 50) {
        this.$router.push('/tips');
      } else {
        this.setAlert({
          type: 'error',
          message: 'El monto mínimo de apuesta es $50.',
          duration: 4000,
          passwordInvalid: true,
        });
      }
    },
    validateAmount() {
      if (Number(this.betAmount) < 50) {
        this.setBetAmount(100);
        this.$refs['input'].value = 100;
      }
    },
    selectAllAmount() {
      this.$refs.input.select();
    },
  },
};
</script>

<style lang="scss" scoped>
.choose-risk {
  &__input_group {
    display: flex;
    align-items: flex-start;
    padding-right: 440px;
  }
  &__input {
    align-items: flex-start;
    display: flex;
    padding: 0px 30px 2px 0px;
    flex-flow: column;
    &__title {
      font-family: Circular-Std-Bold;
      font-size: 10px;
      letter-spacing: 0.17px;
      text-transform: uppercase;
      color: #fff;
    }
    &__box,
    &__box_user {
      display: flex;
      font-family: Avenir-Pro-Bold;
      font-size: 14px;
      min-width: 134px;
      letter-spacing: -0.11px;
      color: #fff;
      align-items: center;
      border: solid 1px #fff;
      width: 180px;
      height: 30px;
      margin: 5px 0 0;
      padding: 3px 52px 0 20px;
      border-radius: 11px;
      input {
        width: 70px;
        background-color: transparent;
        font-family: Avenir-Pro-Bold;
        outline: none;
        border: none;
        color: #fff;
        &:focus {
          border: none;
          outline: none;
        }
      }
      span:last-child {
        font-family: Avenir-Regular;
        font-size: 13px;
        line-height: 2.1;
        letter-spacing: -0.09px;
        color: #fff;
      }
    }
    &__box_user {
      padding: 2px 22px 1px 22px;
      align-items: center;
      justify-content: center;
      width: auto;
      border: solid 3px #c9e8af;
    }
  }
  &-container {
    padding: 45px 0px 1rem 0px;
    min-height: 100%;
    height: max-content;
    display: flex;
    flex-flow: column;
    justify-content: start;
    align-items: center;
    background: url('https://az755631.vo.msecnd.net/golstats-bets/set-bet-page.jpg') center/cover no-repeat;
  }
  &__title {
    font-family: Circular-Std-Bold;
    font-size: 25px;
    letter-spacing: -1.36px;
    margin: 20px;
    color: #fff;
    padding: 5px 140px;
    border-radius: 13px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(225, 225, 225, 0.14);
  }

  &__body {
    margin-top: 40px;
    width: 365px;
    padding: 25px 27px 21px 15px;
    border-radius: 4px;
    border: solid 1px #4c5e3c;
    background-color: rgba(0, 0, 0, 0.25);
  }
  &__button {
    padding: 0px 25px;
    margin-top: 40px;
    border-radius: 21px;
    border: solid 2px #c9e8af;
    background-color: transparent;
    font-family: Circular-Std;
    font-size: 15px;
    height: 32px;
    letter-spacing: -0.12px;
    color: #ffffff;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: #b9e097;
    }
  }
}
@media only screen and (max-width: 768px) {
  .choose-risk {
    &-container {
      padding: 25px 0px 1rem 0px;
      background: url('https://az755631.vo.msecnd.net/golstats-bets/set-bet-mobile.jpg') center/cover no-repeat;
    }
    &__title {
      margin: 20px 10%;
      padding: 10px;
    }
    &__body {
      flex-flow: column;
      align-items: center;
      border: unset;
      margin-top: 20px;
      padding: unset;
      border: unset;
      background-color: unset;

      .risk-checkbox {
        border-radius: 8px;
        border: solid 2px rgba(255, 255, 255, 0.5);
        background-color: rgba(0, 0, 0, 0.2);
        padding: 5px;
        margin: 8px 0px;

        &.selected {
          border-color: #c9e8af;
        }
      }
    }
    &__option {
      border-radius: 13px;
      border: solid 1px rgba(255, 255, 255, 0.43);
      background-color: rgba(29, 29, 29, 0.2);
      margin-bottom: 20px;
      &__button {
        width: 100%;
        margin-bottom: 0px;
      }
      &__first_paraph {
        &.mat-18 {
          margin-top: 0px;
        }
      }
      &__first_paraph,
      &__second_paraph {
        text-align: center;
        font-size: 12px;
        padding: 8px 15px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .choose-risk {
    &__body {
      padding: 0px 10%;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .choose-risk-container {
    zoom: 1.2;
  }
}
</style>
