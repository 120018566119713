<template>
  <div class="is-flex" @click="setChecked">
    <div class="custom-checkbox">
      <div :class="{ checked: isChecked }" :style="`background-color: ${selectColor}`"></div>
    </div>
    <label
      :style="
        `color: ${isChecked ? selectColor : 'white'};
    font-family:${isChecked ? 'Avenir-Demi' : 'Avenir-Regular'}`
      "
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  data: () => ({
    isChecked: false,
    text: '',
  }),
  props: {
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    selectColor: {
      type: String,
      default: '#2b66aa',
    },
    textValue: {
      type: [String, Number],
      default: '',
    },
    hasTextValue: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function(newVal) {
        if (this.hasTextValue) {
          this.isChecked = this.textValue === newVal;
          this.text = newVal;
        } else {
          this.isChecked = newVal;
        }
      },
    },
  },
  methods: {
    setChecked() {
      if (this.hasTextValue) {
        this.text = this.textValue;
        this.$emit('input', this.textValue);
      } else {
        this.isChecked = !this.isChecked;
        this.$emit('input', this.isChecked);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.is-flex {
  align-items: center;
}
.custom-checkbox {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin: 0px 5px;
  border-radius: 8px;
  border: solid 1.1px #d0d0d0;
  background-color: #232323;
  position: relative;
  display: flex;
  font-family: Avenir-Regular;
  justify-content: center;
  align-items: center;
}
.checked {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .is-flex {
    font-size: 13px;
  }
}
</style>
